

































import { Component, Vue, Prop } from "vue-property-decorator";
import { buttonOptions } from "@/components/buttons/buttonOptions";
import { UtilsString } from "@/utils/utils-string";
import { ssmPermissionService } from "@/shared/services/PermissionService";

@Component({
  components: {},
})
export default class Opciones extends Vue {
  @Prop() allbuttons!: buttonOptions[];

  visible(index: number) {
    var Xbtn = this.allbuttons[index];
    if (Xbtn.permisos.length === 0) {
      if (Xbtn.permiso === UtilsString.Empty) {
        return true;
      }
      return ssmPermissionService.hasPermission(Xbtn.permiso);
    } else {
      for (let i = 0; i < Xbtn.permisos.length; i++) {
        var elemento = ssmPermissionService.hasPermission(Xbtn.permisos[i]);
        if (elemento) {
          return true;
        }
      }
    }
    return false;
  }
}
